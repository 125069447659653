<template>
  <!-- Вкладка "Інші дзвінки" -->
    <PageHeader :title="title" />
    <div class="row g-4 mb-3">
      <div class="col-sm-auto">
        <div>
          <!-- Додати групу -->
          <a @click="modalBox = true" class="btn btn-success"
            ><i class="ri-add-line align-bottom me-1"></i> {{ $t('Add') }}</a
          >
        </div>
      </div>
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
          <div class="search-box ms-2">
            <!-- Пошук -->
            <input type="text" class="form-control" v-model="search" :placeholder="$t('searc_inp')" @change="searchB(this.search)"/>
            <i class="ri-search-line search-icon"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-0 text-center text-sm-start align-items-center mb-4" >
      <div class="col-sm-6" >
          <div >
              <p class="mb-sm-0 text-muted">{{ $t('Total') }} <span class="fw-semibold">{{ objPages.show }}</span> {{ $t('outOf') }} <span class="fw-semibold text-decoration-underline">{{ objPages.total }}</span></p>
          </div>
      </div>
      <div class="col-sm-6" >
          <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
              <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" >
                <!-- Змінити ліміт -->
                <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                  <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                </select>
              </div>
              <li v-for="(pageNumber, index) in objPages.links" :key="index" :class="`page-item copy ${pageNumber.current == 'true' ? 'active' : null}`">
                  <a class="page-link" @click="getdata(pageNumber.page)">{{ pageNumber.page }}</a>
              </li>
          </ul>
      </div>
    </div>

    <b-row>
      <!-- Перерахуємо групи -->
      <div
        class="col-xxl-3 col-sm-6 project-card"
        v-for="(item, index) of rows"
        :key="index"
      >
        <div class="card" >
          <div class="card-body">
            <div :class="`p-3 mt-n3 mx-n3 ${this.mutatePriority(item.priority).color} rounded-top`">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <!-- Назва категорії -->
                  <h5 class="mb-0 fs-14 click" 
                    @click="$router.push({ name: 'pages-list-open-otherCall', params: { id: item.categoryId, title: item.categoryName, task: item.taskName } })"
                  >
                    <b>{{ item.categoryName }}</b>
                  </h5>
                </div>
                <div class="flex-shrink-0">
                  <div class="d-flex gap-1 align-items-center my-n2">
                    <!-- Дропдаун -->
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <MoreHorizontalIcon class="icon-sm"></MoreHorizontalIcon>
                      </button>

                      <div class="dropdown-menu dropdown-menu-end">
                        <!-- Запит на зміну -->
                        <button
                          class="dropdown-item"
                          @click="editModal(item)"
                          ><i
                            class="ri-pencil-fill align-bottom me-2 text-muted"
                          ></i>
                          {{ $t('Edit') }}</button
                        >
                        <div class="dropdown-divider"></div>
                        <!-- Запит на видалення -->
                        <button
                          class="dropdown-item"
                          @click="removeItem(item)"
                          ><i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          {{ $t('Delete') }}</button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3">
              <div class="row gy-3">
                <div class="col-6">
                  <!-- Поле пріоритетк -->
                  <div>
                    <p class="text-muted mb-1">{{ $t('priority') }}</p>
                    <div :class="`badge ${this.mutatePriority(item.priority).color} fs-12`">
                      {{ this.mutatePriority(item.priority).name }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div>
                    <p class="text-muted mb-1">{{ item.taskName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
    </b-row>

  <!-- створення категорії -->
  <createBox 
    v-if="modalBox" 
    @close="closeCreate" 
    @getdata="getdata"
    :obj="this.form"
    :perm="this.permToEdit"
  />
  <!-- створення категорії -->

  <!-- видалити категорію -->
  <modalremove 
      v-if="dialogShow" 
      @close="dialogShow = false" 
      @remove="deleteCategory()" 
  />
    <!-- видалити категорію -->
</template>
  
<script>
import PageHeader from "@/components/page-header";
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
import createBox from './newlist'
import modalremove from '@/components/modal/modalremove'
import { mutatePriorityOther } from '@/usabilityScripts/globalMutate.js'
import { OtherCalls } from "@/API.js";
import { storeS } from "@/store";
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new OtherCalls();
  
export default {
    components: {
      PageHeader,
      MoreHorizontalIcon,
      createBox,
      modalremove
    },
    data() {
      return {
        title: this.$t('other'),
        form:"",
        permToEdit: false,
        modalBox: false,
        dialogShow: false,
        rows: [],
        objCard: '',
        objPages: '',
        limit: '25',
        limits: [
          { value: '25'},
          { value: '50'},
          { value: '75'},
          { value: '100'},
        ],
        dataParams: {
            status: true,
            page: "projects",  
        },
        objParams:{
          page: '1',
          pagelimit: '25',
          search: ''
        },
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      searchB(search) {
        // функція пошуку
        this.objParams.search = search
        this.getdata()
      },
      changelimit(limit) {
        // зміна ліміту
        this.objParams.pagelimit = limit
        this.getdata()
      },
      getdata(page){
        // отримати папки
        this.objParams.page = page != undefined ? page : '1';
        apiServe.getCategories(this.objParams).then(result => {
          if(result.status == 'done'){
            this.rows = result.data.items;
            this.objPages = result.data;
          } else {
            this.$toast.error(this.$t('error') + ` #100`);

            var errorText = 'Помилка отримання категорій'; 
            var statusRequest = result.status;
            var api = 'getCategories';
            var fileRequest = 'src/views/calls/other/other.vue';
            var params = this.objParams;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
        })
      },
      editModal(e){
        // Відкрити вікно редагування
        this.permToEdit = true;
        this.form = e;
        this.modalBox = true
      },
      closeCreate(){
        // закрити вікно свторення
        this.permToEdit = false;
        this.modalBox = false
      },
      removeItem(e){
        // відкриття модалки підтвердження
        this.objCard = e;
        this.dialogShow = true;
      },
      deleteCategory(){
        apiServe.deleteCategory(this.objCard.categoryId).then(result => {
          if(result.status == 'done'){
            this.dialogShow = false;
            this.getdata();
            this.$toast.success(this.$t('Removed'));
          } else {
            this.$toast.error(this.$t('error') + ` #100`);

            var errorText = 'Помилка видалення категорії'; 
            var statusRequest = result.status;
            var api = 'deleteCategory';
            var fileRequest = 'src/views/calls/other/other.vue';
            var params = this.objCard.categoryId;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
        })
      },
      mutatePriority(e){
        // мутейт пріоритету
        return mutatePriorityOther(e);
      },
    },
    computed: {
      perms() {
        return storeS.perms
      },
      user() {
        return storeS.userbase
      }
    },
    mounted() {
      this.eventBus.on('saveApplic', (status) => {
        if(status == true) {
          this.getdata();
          // оновити дані
        }
      })
    }
  };
</script>
  
<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.howto_block {
  margin-left:10px;
}
.howto_block i {
  font-size: 23px;
  opacity: 0.5;
}
.howto_block i:hover{
  opacity: 1;
}

.howto_block {
  position: relative;
  display: inline-block;
}

.howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
}

.howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
