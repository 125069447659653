<template>
    <modal>
        <template v-slot:title>{{ $t('Group') }} {{ form.categoryName }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                <input type="text" class="form-control" v-model="form.categoryName">
            </div>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                <Multiselect 
                    v-model="form.priority"
                    :close-on-select="true"
                    :searchable="true"
                    :create-option="false"
                    :options="listPriority"
                    :placeholder="$t('priority')"
                />
            </div>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Task') }}</h4>
                <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.taskName" placeholder="Введите полезную информацию для будущего звонка"></textarea>
            </div> 
        </template>
        <template v-slot:footer-bottom>
            <!-- <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </a> -->
            <button v-if="perm != true" type="button" class="btn btn-success" @click="add">{{ $t('Add') }}</button>
            <button v-else type="button" class="btn btn-warning" @click="edit">{{ $t('Edit') }}</button>
        </template>
    </modal>
</template>
 
<script>
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { OtherCalls } from '@/API.js';
import { storeS } from '@/store';
 
let apiServe = new OtherCalls();
 
export default ({
    props: ['perm', 'obj'],
    components: {
        modal,
        Multiselect
    },
    data(){
        return {
        form: {
            categoryName: "",
            priority: 0,
            taskName: ""
        },
        listPriority: [
            {
                "label": this.$t('priorityHigh'),
                "value": 2
            },
            {
                "label": this.$t('priorityMedium'),
                "value": 1
            },
            {
                "label": this.$t('priorityLow'),
                "value": 0
            }
        ],
        }
    },
    
    created(){
        if(this.perm == true) {
            this.form = this.obj
        }
    },
    methods: {
        add() {
            if (!this.form.categoryName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (this.form.priority == '') {
                //перевіряємо чи вказаний пріоритет
                this.$toast.error(this.$t('EnterPriority'));
            }
            if (!this.form.taskName) {
                //перевіряємо чи вказано завдання
                this.$toast.error(this.$t('EnterDescTask'));
            }
                apiServe.addCategory(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                        this.$emit('getdata')
                    } else {
                        this.$toast.error(this.$t('error') + " #AddCategoryCalls");
                    }
                })
            },
        edit(){
            apiServe.editCategory(this.form.categoryId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('close')
                    this.$emit('getdata')
                }
            })
        }
    },
    mounted(){
    },
    computed: {
        user() {
            return storeS.userbase
        }
    }
 })
 </script>
 